/* ----VARIABLES---- */
/* Font sizes */
$fs-xxl: 2.125rem; // 34px
$fs-xl: 1.75rem; // 28px
$fs-lg: 1.375rem; // 22px
$fs-sm: 1.25rem; // 20px
$fs-xs: 1.0625rem; // 17px
$fs-xxs: 0.875rem; // 14px
$fs-xxxs: 0.75rem; // 12px
$fs-xsm: 1.125rem; // 18px

/* Font weights */
$fw-thin: 300;
$fw-normal: 400;
$fw-semibold: 500;
$fw-bold: 600;

/* Line heights */
$lh-xxl: 2.5rem; //40px
$lh-xl: 2.0625rem; //33px
$lh-lg: 1.625rem; //26px
$lh-sm: 1.5rem; // 24px
$lh-xs: 1.25rem; // 20px
$lh-xxs: 1.0625rem; // 17px
$lh-xxxs: 1rem; // 16px

/* Breakpoints */
$breakpoints: (sm:20rem,
  //320px
  md: 48rem,
  // 768px
  lg: 64rem,
  // 1024px
  xl: 80rem,
  // 1280px
);

$primary-color: #6FAFB9;
$primary-font: 'HoneyLemon-Regular';
$regular-font: 'NoirPro-Regular';
$medium-font: 'NoirPro-Medium';