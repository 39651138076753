@import "../../../../styles/all.scss";

.container {
  width: 100%;
  overflow: hidden;
}

.label {
  color: $dark;
  @include margin($marginBottom:18px);
}

.textarea {
  resize: vertical;
  width: 100%;
  border: none;
  border-bottom: 1px solid rgba(226, 226, 226, 0.455);
  box-sizing: border-box;
  color: $dark;
  @include padding($paddingBottom:11px);

  &::placeholder {
    color: rgba(149, 149, 149, 0.386);
  }

  &:focus::placeholder {
    color: transparent;
  }

  @include mobile {
    min-height: 90px;
  }

  @include tablet {
    min-height: 110px;
  }
}

.input-error {
  border-color: $red;
}

.error-message {
  color: $red
}