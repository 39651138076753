@import "../../../../../../styles/all.scss";

.list {
  width: 100%;
  @include flexbox($wrap:wrap, $justifyContent:space-between);

  @include tablet {
    @include flexbox($justifyContent:inherit);
  }
  
  &.list-wrapper {
    justify-content:flex-start;
  }
}

.list-item {
  @include margin($margin:0 8px 8px 0);
  @include padding($padding:18px 10px 10px 10px);
  background: $white;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
  border-radius: 25px;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
  overflow: hidden;

  @include tablet {
    @include margin($margin:0 20px 18px 0);
    @include padding($padding:18px 18px 10px 18px);
  }

  &:hover {
    background-color: #ffb7ae90;

    div {
      color: $white;
    }
  }
}

.list-item-selected {
  background-color: $peach;

  div {
    color: $white;
  }
}

.list-item-image {
  width: 100%;
  @include flexbox($justifyContent:center);
  @include margin($marginBottom:9px);

  img {
    width: 46px;
    height: 46px;
    border-radius: 50%;
  }
}

.list-item-header {
  width: 80px;
  text-align: center;
  color: $dark;
  font-family: $medium-font;
}

.list-item-price {
  @include margin($marginTop:3px);
  width: 80px;
  text-align: center;
  color: $pink;
}

.list-wrapper {
  margin-top:16px;
  margin-bottom:16px;
}

.increment-container {
  margin: 0 20px 18px 0;
}

.increment-button {
  color: $peach
}