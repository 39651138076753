@import "../../../../styles/all.scss";

.container {
  width: 100%;
  color: $dark;
}

.description {
  @include margin($marginTop:5px);
  color: $dark;
}