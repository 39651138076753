@import "../../../styles/all.scss";

.container {
    width: 100%;
}

.top-section-content {
    width: 100%;
    position: relative;
    @include backgroundImage($imgpath:'../../../assets/images/home-top-clip.svg', $size:cover, $position: bottom);

    & .start-order-box {
        @include margin($marginTop:34px);
        @include flexbox($alignItems:center, $justifyContent:center, );

        button {
            color: $pink;

            svg path {
                fill: $white;
                stroke: $white;
            }

            div {
                font-size: $fs-lg;
                line-height: $lh-lg;
            }
        }
    }

    height: 385px;

    @include tablet {
        height: 511px;
    }

    @include laptop {
        height: 600px;
    }
}

.top-section-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    img {
        width: 100%;
    }
}

.top-section-arrow {
    position: absolute;
    bottom: -115px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
}

.how-to-order-container {
    width: 100%;
    @include margin($marginTop:130px);
    @include flexbox($direction:column, $justifyContent:center);
    position: relative;
    overflow: hidden;

    img {
        width: 267px;
        height: 30px;
        @include margin($margin:0 auto);
    }

    & .start-order-box {
        @include margin($marginTop:41px);
        @include flexbox($alignItems:center, $justifyContent:center);

        button {
            color: $white;

            svg path {
                fill: $lilac;
                stroke: $lilac;
            }
        }
    }
}

.steps {
    @include margin($marginTop:30px);
}

.step {
    @include flexbox($direction:column, $alignItems:center);
    @include margin($marginBottom:26px);
    text-align: center;
    color: $dark;

    img {
        height: 54px;
    }

    h4 {
        @include margin($marginTop:10px);
        max-width: 50%;
    }

    p {
        @include margin($marginTop:5px);
        max-width: 50%;
    }
}

.allergen-container {
    @include margin($margin:50px auto 0 auto);
    @include flexbox($direction:column, $alignItems:center);

    width: 70%;
    background: #FFFFFF;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    @include padding($padding:37px 27px 27px);
    text-align: center;
    color: $dark;

    & .allergen-btn-container {
        @include margin($marginTop:17px);

        button {
            color: $white;

            svg path {
                fill: $blue;
                stroke: $blue;
            }
        }
    }
}

.footer-section {
    @include margin($marginTop:36px);
    @include padding($padding:52px 0);
    @include flexbox($direction:column, $alignItems:center);
    @include backgroundImage($imgpath:'../../../assets/images/home-footer-section.svg', $size:cover);

    @include laptop {
        @include padding($padding:90px 0 52px);
    }

    .main-logo {
        width: 40px;
        height: 59px;
    }

    @include tablet {
        @include margin($marginTop:50px);
    }
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $white;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    border-radius: 25px;
    @include padding($padding:30px);

    min-width: 70%;

    @include tablet {
        @include padding($padding:40px);
        min-width: 400px;
    }

    @include laptop {
        min-width: 500px;
    }

    @include desktop {
        min-width: 600px;
    }

    p {
        font-family: $regular-font;
        text-align: center;
    }
}