@import "../../../../styles/all.scss";

.container {
  width: 100%;
  @include flexbox($alignItems: center);

  button {
    border: none;
    background-color: transparent;
    @include flexbox($grow:1, $justifyContent: center, $alignItems: center);
    cursor: pointer;
    color: inherit;

    &:focus,
    &:active {
      outline: 0
    }

    &:disabled {
      color: $gray;

      ~span {
        color: $gray;
      }
    }
  }

  span {
    @include padding(0 5px)
  }
}