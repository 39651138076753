@import "../../../../../styles/all.scss";

.list {
    width: 100%;
    @include flexbox($wrap:wrap);
}

.list-item {
    @include padding($padding:16px 30px);
    @include margin($margin:0 20px 8px 0);

    background: $white;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    border-radius: 31px;
    transition: 0.3s all ease-in-out;
    cursor: pointer;

    &:hover {
        background-color: #ffb7ae90;

        div {
            color: $white;
        }
    }

    @include tablet {
        @include margin($margin:0 20px 18px 0);
    }
}

.list-item-selected {
    background-color: $peach;

    &:hover {
        background-color: $peach;
    }

    div {
        color: $white;
    }
}

.list-item-image {
    width: 100%;
    @include flexbox($justifyContent:center);

    @include margin($marginBottom:9px);

    img {
        width: 63px;
        height: 61px;
        border-radius: 50%;
    }
}

.list-item-header {
    width: 80px;
    color: $dark;
    text-align: center;
    font-family: $medium-font;
}

.list-item-disabled {
    background-color: $gray;
    box-shadow: none;
    user-select: none;

    div {
        background-color: $gray !important;
        color: #D6D6D6 !important
    }

    &:hover {
        background-color: $gray;
        cursor: default;
    }
}