@import "../../../styles/all.scss";

.container {
    width: 100%;
}

.content {
    @include margin($margin:70px 0);
    @include padding($padding:0 18px);

    @include tablet {
        @include padding($padding:0 30px);
    }

    @include laptop {
        @include padding($padding:0 50px);
    }

    @include desktop {
        @include margin($margin:80px 0);
        @include padding($padding:0 70px);
    }
}

.stepper {
    @include margin($marginBottom: 30px);
    max-width: 100%;

    @include tablet {
        max-width: 350px;
    }
}