@import "../../../../styles/all.scss";

.list {
    width: 100%;
    @include margin($marginTop:50px);
}

.list-item {
    @include flexbox();
    @include padding($padding:22px 17px);
    background: $white;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    border-radius: 25px;
    
    .list {
        padding-top:12px;
        border:1px solid red;
    }
}

.list-item-image {
    @include margin($marginRight:17px);

    img {
        width: 38px;
        height: 39px;
        border-radius: 50%;
    }
}

.list-item-content {
    @include flexbox($direction:column, $grow:1);
    position: relative;

    p,
    h1,
    h4 {
        color: $dark
    }

    h1 {
        font-weight: $fw-normal;
    }

    h4 {
        width: 90%;
        color: $dark;
        font-family: $medium-font;
    }
}

.list-item-price {
    color: $pink
}

.list-item-actions {
    @include flexbox($alignItems:center, $justifyContent:space-between);
    @include margin($marginTop:8px);
}

.increment-button {
    width: 85px;
    color: $pink;

    span,
    button {
        font-size: $fs-lg;
        line-height: $lh-lg;
    }
}

.close-icon {
    position: absolute;
    top: -3px;
    right: 0;
    @include margin($marginRight:5px);
    background-color: transparent;
    border: none;
    width: 30px;
    height: 30px;
    cursor: pointer;

    svg {
        width: 14px;
        height: 14px;
    }

    &:disabled {
        svg path {
            fill: $gray
        }
    }
}

.list {
    margin-top:16px;
}