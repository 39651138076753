@import "../../../../../styles/all.scss";

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $white;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
  border-radius: 25px;
  @include padding($padding:30px);
  min-width: 70%;
  font-weight: $fw-normal;
  font-family: $regular-font;

  @include tablet {
    @include padding($padding:40px);
    min-width: 400px;
  }

  @include laptop {
    min-width: 500px;
  }

  @include desktop {
    min-width: 600px;
  }
}

.btn-container {
  @include margin($marginTop:25px);
  @include flexbox($justifyContent:flex-end);
  width: 100%;
}

.close-button {
  color: #ffffff;
  max-width: 160px;

  svg path {
    fill: $lilac;
    stroke: $lilac;
  }

  @include tablet {
    max-width: 180px;
  }

  @include laptop {
    max-width: inherit;
  }
}