@import "../../../../../styles/all.scss";

.drawer {
  div:nth-child(3) {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }
}

.modal {
  background: $white;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
  border-radius: 25px;
  @include padding($padding:30px);

  @include tablet {
    @include padding($padding:40px);
  }

  color:$dark
}

.header-content {
  @include flexbox($justifyContent:space-between);
  @include padding($paddingBottom:10px);
  border-bottom: 1px solid rgba(226, 226, 226, 0.455);
}

.product-name {
  @include flexbox($alignItems:center);

  p {
    @include margin($marginLeft:13px);
    font-weight: $fw-semibold;
  }
}


.close-icon {
  background-color: transparent;
  border: none;
  width: 30px;
  height: 30px;
  cursor: pointer;

  svg {
    width: 14px;
    height: 14px;
  }

  &:disabled {
    svg path {
      fill: $gray
    }
  }
}

.actions-container {
  @include flexbox($alignItems:center, $justifyContent:space-between);
  @include margin($marginTop:24px);
}

.increment-button {
  width: 85px;
  color: $pink;

  span,
  button {
    font-size: $fs-lg;
    line-height: $lh-lg;
  }
}

.choose-button {
  width: 170px;
  color: #ffffff;

  svg path {
    fill: $lilac;
    stroke: $lilac;
  }
}

.select-size-container,
.select-flavour-container {
  @include margin($marginTop:24px);

  h1,
  p,
  input {
    font-family: 'NoirPro-Regular';
  }
}

.size-item,
.flavour-item {
  @include flexbox($alignItems:center);

  &:not(:first-child) {
    @include margin($marginTop:19px);
  }
}

.radio-text {
  @include flexbox($alignItems:center);

  span {
    @include margin($marginLeft:15px);
  }
}

.radio-input {

  label,
  span,
  h1 {
    font-family: 'NoirPro-Regular';
  }
}