@import "../../../../../styles/all.scss";

.section-header {
  @include margin($marginBottom:20px);
}

.scoop-list-container {
  @include margin($marginTop:30px);

  @include tablet {
    @include margin($marginTop:50px);
  }
}

.next-steps-container {
  color: $dark;
  @include margin($marginTop:30px);

  @include tablet {
    @include margin($marginTop:50px);
  }


  p {
    @include margin($marginTop:8px);
  }
}

.button-container {
  width: 100%;
  @include margin($marginTop:22px);
  @include flexbox($justifyContent:flex-end);
}

.choose-button {
  color: #ffffff;

  svg path {
    fill: $lilac;
    stroke: $lilac;
  }
}
