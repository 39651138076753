@import "../../../../../styles/all.scss";

.list {
    width: 100%;
    @include flexbox($wrap:wrap, $justifyContent:space-between);
}

.list-item-container {
    position: relative;
    @include margin($margin:0 8px 8px 0);

    @include tablet {
        @include margin($margin:0 20px 18px 0);
    }
}

.list-item {
    @include padding($padding:21px 10px);
    height: 140px;
    background: $white;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    border-radius: 25px;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    overflow: hidden;

    @include tablet {
        @include padding($padding:30px 24px);
    }

    &:hover {
        background-color: #ffb7ae90;

        div {
            color: $white;
        }
    }
}

.list-item-selected {
    background-color: $peach;

    div {
        color: $white;
    }
}

.list-item-image {
    width: 100%;
    @include flexbox($justifyContent:center);

    @include margin($marginBottom:9px);

    img {
        width: 49px;
        height: 49px;
        border-radius: 50%;
    }
}

.list-item-header {
    width: 80px;
    color: $dark;
    text-align: center;
    font-family: $medium-font;
}

.new-mark {
    position: absolute;
    top: 12px;
    right: 12px;

    @include tablet {
        width: 30px;
        height: 28px;
    }
}

.exclusive-mark {
    position: absolute;
    top: 12px;
    right: 12px;

    @include tablet {
        width: auto;
        height: 17px;
    }
}

.increment-container {
    @include margin($marginTop: 10px);
}

.increment-button {
    color: $peach
}