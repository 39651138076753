@import "../../styles/all.scss";

.container {
    box-sizing: border-box;
    width: 100%;
    position: relative;
}

.main-content {
    @include flexbox($grow:1);
}