@import "../../../../../../styles/all.scss";

.list-wrapper {
  margin-bottom:30px;
}

.list {
  width: 100%;
  @include flexbox($wrap:wrap);
}

.list-item {
  @include padding($padding:16px 30px);
  @include margin($margin:0 20px 8px 0);

  background: $white;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
  border-radius: 31px;
  transition: 0.3s all ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: #ffb7ae90;

    div {
      color: $white;
    }
  }

  @include tablet {
    @include margin($margin:0 20px 18px 0);
  }
}

.list-item-selected {
  background-color: $peach;

  &:hover {
    background-color: $peach;
  }

  div {
    color: $white;
  }
}

.list-item-disabled {
  background-color: $gray;
  box-shadow: none;
  user-select: none;

  div {
    background-color: $gray !important;
    color: #D6D6D6 !important
  }

  &:hover {
    background-color: $gray;
    cursor: default;
  }
}

.list-item-colors {
  width: 100%;
  @include flexbox($justifyContent:center);
  @include margin($marginBottom:9px);

  div {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 1px solid $white;

    &:not(:first-child) {
      margin-left: -60px;
    }
  }
}

.list-item-header {
  width: 80px;
  text-align: center;
  color: $dark;
  font-family: $medium-font;
}

.list-item-price {
  @include margin($marginTop:3px);
  width: 80px;
  text-align: center;
  color: $pink;
}

.list-item-colors {
  img {
    max-width:60px;
  }
}