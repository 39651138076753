//NOTE: Noir pro installed from https://en.bestfonts.pro/font/noir-pro
// Honey Lemon installed from https://www.onlinewebfonts.com/download/169c2f6fe4201812ab21ce10651a2d75
@font-face {
    font-family: 'NoirPro-Regular';
    /* IE9 Compat Modes */
    src: url('assets/fonts/NoirPro/Regular/NoirPro-Regular.eot');
    src: url('assets/fonts/NoirPro/Regular/NoirPro-Regular.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('assets/fonts/NoirPro/Regular/NoirPro-Regular.woff') format('woff'),
        /* Pretty Modern Browsers */
        url('assets/fonts/NoirPro/Regular/NoirPro-Regular.ttf') format('truetype');
    /* Safari, Android, iOS */
}

@font-face {
    font-family: 'NoirPro-Light';
    /* IE9 Compat Modes */
    src: url('assets/fonts/NoirPro/Light/NoirPro-Light.eot');
    src: url('assets/fonts/NoirPro/Light/NoirPro-Light.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('assets/fonts/NoirPro/Light/NoirPro-Light.woff') format('woff'),
        /* Pretty Modern Browsers */
        url('assets/fonts/NoirPro/Light/NoirPro-Light.ttf') format('truetype');
    /* Safari, Android, iOS */
}

@font-face {
    font-family: 'NoirPro-Medium';
    /* IE9 Compat Modes */
    src: url('assets/fonts/NoirPro/Medium/NoirPro-Medium.eot');
    src: url('assets/fonts/NoirPro/Medium/NoirPro-Medium.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('assets/fonts/NoirPro/Medium/NoirPro-Medium.woff') format('woff'),
        /* Pretty Modern Browsers */
        url('assets/fonts/NoirPro/Medium/NoirPro-Medium.ttf') format('truetype');
    /* Safari, Android, iOS */
}

@font-face {
    font-family: 'HoneyLemon-Regular';
    /* IE9 Compat Modes */
    src: url('assets/fonts/HoneyLemon/Honey-Lemon.eot');
    src: url('assets/fonts/HoneyLemon/Honey-Lemon.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('assets/fonts/HoneyLemon/Honey-Lemon.woff') format('woff'),
        /* Pretty Modern Browsers */
        url('assets/fonts/HoneyLemon/Honey-Lemon.ttf') format('truetype');
    /* Safari, Android, iOS */
}

* {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    list-style: none;
    text-decoration: none;
    font-family: 'NoirPro-Light', Fallback, sans-serif;
    outline: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
}

.webkit-appearance {
    -webkit-appearance: none;
}

button {
    transition: 0.3s all ease-in-out;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    -webkit-overflow-scrolling: auto
        /* need to test after deploy */
}

::-webkit-scrollbar-track {
    background-color: #ffffff;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-image: -webkit-gradient(linear,
            left bottom,
            left top,
            color-stop(0.44, #f44974),
            color-stop(0.72, #f44974),
            color-stop(0.86, #f44974));
}

::-webkit-scrollbar:horizontal {
    width: 5px;
}