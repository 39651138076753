@import "../../../styles/all.scss";

.container {
    width: 100%;
}

.top-section {
    position: relative;
    width: 100%;
    @include backgroundImage($imgpath:'../../../assets/images/home-top-clip.svg', $size:cover, $position: bottom);
    height: 450px;

    @include tablet {
        height: 450px;
    }

    @include laptop {
        height: 470px;
    }

    cursor: default;
}

.top-section-centent {
    @include flexbox($direction:column, $alignItems:center, $justifyContent:center);
    @include padding($paddingTop:52px)
}

.shop-image {
    @include margin($marginTop:25px);
    height: 49px;
    width: 54px;
}

.top-section-arrow {
    position: absolute;
    bottom: -80px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
}

.success-message {
    text-align: center;
    color: $white;

    h4 {
        @include margin($marginTop:14px)
    }

    p {
        @include margin($marginTop:5px);
        font-weight: $fw-thin;
    }
}


.order-code {
    @include padding($paddingTop:5px);
}

.wait-time {
    @include padding($padding:15px 26px);
    @include margin($marginTop:11px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $white;
    color: $pink;
    max-width: 250px;
    border-radius: 6px;

    svg {
        @include margin($marginRight: 13px);
    }

    span {
        @include margin($marginRight: 6px);
    }

    p {
        font-size: 0.875rem;
    }

    .heading {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
    }
}

.wait-time-text {
    font-weight: $fw-bold;
}

.signup-container {
    @include margin($marginTop:100px);
    @include padding($padding:40px 21px);
    color: $white;
    background-color: $blue;
    @include flexbox($direction:column, $alignItems:center);

    h1 {
        @include margin($marginBottom:5px);
    }

    input {
        @include margin($margin:15px 0 20px);
        @include padding($padding:12px 19px);
        background-color: $white;
        border-radius: 6px;
        color: $dark;
        width: 290px;
        border: none;

        &::placeholder {
            color: rgba(149, 149, 149, 0.386);
        }

        &:focus::placeholder {
            color: transparent;
        }
    }
}

.signup-button {
    color: $blue;

    svg path {
        fill: $white;
        stroke: $white;
    }

    &:disabled {
        svg path {
            fill: $white !important;
            stroke: $white !important;
        }
    }
}

.similar-offers-container {
    @include padding($padding:60px 24px);
    @include margin($marginTop:56px);
    @include flexbox($direction:column, $alignItems:center);

    @include laptop {
        @include padding($padding:60px 32px);
    }
}

.offer-item {
    width: 90%;
    @include padding($padding:28px 18px 15px);
    @include flexbox($alignItems:center);

    &:not(:last-child) {
        @include margin($marginBottom:14px);
    }

    img {
        width: 85px;
        height: 70px;
        border-radius: 12px;
    }

    background: #FFFFFF;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    border-radius: 25px;
}

.offer-info {
    @include padding($paddingLeft:20px);
}

.offer-header {
    color: black;
    font-family: $medium-font;
}

.offer-description {
    @include margin($marginTop:5px);
    color: $dark;
}

.offer-info-url {
    font-family: $medium-font;
    @include margin($marginTop:11px);
    @include flexbox($alignItems:center);
    color: #6FAFB9;

    svg {
        width: 10px;
        @include padding($paddingLeft:18px);

        path {
            stroke: #6FAFB9;
        }
    }
}

.footer {
    @include margin($marginTop:40px);
    @include flexbox($direction:column, $alignItems:center);
    @include padding($padding:36px 0);
    background-color: $lilac;
    color: $white;
}

.social-networks {
    @include margin($marginTop:17px);
    @include flexbox($alignItems:center);
}

.social-item {
    a {
        display: inline-block;
        height: 100%;
        width: 100%;
    }

    &:not(:first-child) {
        @include margin($marginLeft:31px);
    }
}