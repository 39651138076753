@import "../../../../../styles/all.scss";

.list {
  width: 100%;
  @include flexbox($wrap:wrap, $justifyContent:space-between);
}

.list-item-container {
  position: relative;
  @include margin($margin:0 8px 8px 0);

  @include tablet {
    @include margin($margin:0 20px 18px 0);
  }
}

.list-item {
  @include padding($padding:21px 10px);
  height: 140px;
  background: $white;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
  border-radius: 25px;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
  overflow: hidden;

  @include tablet {
    @include padding($padding:30px 24px);
  }

  &:hover {
    background-color: #ffb7ae90;

    div {
      color: $white;
    }
  }
}

.tabs-container {
  width: 100%;
  @include flexbox($justifyContent:center);
}

.tabs {
  @include flexbox();
  @include margin($marginBottom:21px);
  height: 40px;
  width: 95%;
  box-sizing: border-box;

  @include tablet {
    max-width: 350px;
  }
}

.tab {
  @include flexbox($justifyContent:center, $alignItems:center, $grow:1);
  border-color: $blue;
  color: $blue;
  border-top: 2px solid;
  border-bottom: 2px solid;

  box-sizing: border-box;
  cursor: pointer;
  font-family: $medium-font;

  &:first-child {
    border-left: 2px solid;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }

  &:last-child {
    border-right: 2px solid;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }

  &:hover {
    color: $white;
    border-color: $blue;
    background-color: $blue;
    opacity: 0.7;
  }
}

.selected-tab {
  color: $white;
  border-color: $blue;
  background-color: $blue;

  &:first-child {
    border-left-color: $blue;
  }

  &:last-child {
    border-right-color: $blue;
  }
}

.sorbet-container,
.vegan-container {
  h1 {
    color: $dark;
    @include margin($marginBottom:10px);
  }
}

.list-item-selected {
  background-color: $peach;

  div {
    color: $white;
  }
}

.list-item-image {
  width: 100%;
  @include flexbox($justifyContent:center);

  @include margin($marginBottom:9px);

  img {
    width: 49px;
    height: 49px;
    border-radius: 50%;
  }
}

.list-item-header {
  width: 80px;
  color: $dark;
  text-align: center;
  font-family: $medium-font;
}

.new-mark {
  position: absolute;
  top: 12px;
  right: 12px;

  @include tablet {
    width: 30px;
    height: 28px;
  }
}

.exclusive-mark {
  position: absolute;
  top: 12px;
  right: 12px;

  @include tablet {
    width: auto;
    height: 17px;
  }
}

.increment-container {
  @include margin($marginTop: 10px);
}

.increment-button {
  color: $peach
}

.next-steps-container {
  color: $dark;
  @include margin($marginTop:30px);

  @include tablet {
    @include margin($marginTop:50px);
  }

  p {
    @include margin($marginTop:8px);
  }
}

.button-container {
  width: 100%;
  @include margin($marginTop:22px);
  @include flexbox($justifyContent:flex-end);
}

.section-header {
  margin-bottom:30px;
}

.choose-button {
  color: #ffffff;

  svg path {
    fill: $lilac;
    stroke: $lilac;
  }
}
