@import "../../../../styles/all.scss";

.section-header {
    @include margin($marginBottom:20px);
}

.section-basket-header {
    text-align: center;
}

.button-container {
    width: 100%;
    @include margin($marginTop:22px);
    @include flexbox($justifyContent:flex-end);
}

.basket-items {
    @include tablet {
        @include margin($marginTop:50px);
        @include flexbox($justifyContent:center, $wrap:wrap);
    }
}

.basket-item {
    @include flexbox($direction:column, $alignItems:center);

    h4,
    p {
        color: $dark;
    }

    h4 {
        font-family: $medium-font;
    }

    p {
        @include margin($marginTop:4px);
    }

    img {
        @include margin($marginBottom:4px);
        width: 39px;
        height: 39px;
        border-radius: 50%;
    }

    &:not(:last-child) {
        @include margin($marginBottom:40px);
    }

    @include tablet {
        @include margin($marginRight:30px);

        img {
            @include margin($marginBottom:8px);
            width: 45px;
            height: 45px;
        }
    }
}

.total-price {
    color: $pink;
    @include margin($marginTop:12px);
}

.increment-container {
    @include margin($marginTop: 10px);
}

.increment-button {
    width: 134px;
    color: $lilac
}

.basket-button-container {
    @include margin($marginTop: 30px);
    @include flexbox($direction:column, $alignItems:center);

    button {
        &:not(:first-child) {
            @include margin($marginTop: 16px);
        }
    }

    @include tablet {
        @include flexbox($justifyContent:center);

        button {
            &:not(:first-child) {
                @include margin($marginTop: 0, $marginLeft: 26px);
            }
        }
    }
}

.add-icecream-button {
    color: $lilac;

    svg path {
        fill: #ffffff;
        stroke: $lilac;
    }
}

.proceed-payment-button {
    color: #ffffff;

    svg path {
        fill: $lilac;
        stroke: $lilac;
    }
}