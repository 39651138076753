@import "../../../../styles/all.scss";

.container {
  height: 53px;
  position: relative;
  border: none;
  background-color: transparent;
  @include flexbox($justifyContent: center, $alignItems: center);
  cursor: pointer;

  &:focus,
  &:active {
    outline: 0
  }

  &:disabled {
    .button-background {
      path {
        fill: $gray;
        stroke: $gray;
      }
    }
  }
}

.button-background {
  @include backgroundImage($position:center, $repeat:no-repeat);
  width: 100%;
  height: 100%;
}

.button-text {
  position: absolute;
  @include flexbox($justifyContent: center, $alignItems: center);
  width: 100%;
  height: 100%;
  font-size: $fs-lg;
  line-height: $lh-lg;
}