@import "../../../../../styles/all.scss";

.container {
    width: 100%;
    @include margin($marginTop:20px);
    position: relative;
}

.content {
    @include padding($paddingBottom:11px);
    border-bottom: 1px solid rgba(226, 226, 226, 0.455);
    box-sizing: border-box;
    color: $dark;
    @include flexbox($alignItems:center, $justifyContent:space-between);
    cursor: pointer;
    user-select: none;
}

.right-part {
    @include flexbox($alignItems:center);
    @include margin($marginLeft:20px);
}

.arrow-icon {
    @include margin($marginLeft:15px);

    svg {
        height: 11px;
        width: 11px;
        transition: 0.2s all ease-in-out;

        path {
            stroke: $dark
        }
    }

    &.open {
        svg {
            transform: rotate(90deg);
        }
    }
}

.list {
    position: absolute;
    width: 100%;
    top: 33px;
    left: 0;
    border-radius: 5px;
    background-color: $white;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    overflow-y: auto;
    max-height: 200px;

    @include tablet {
        max-height: 250px;
    }

    @include laptop {
        max-height: 300px;
    }
}

.list-item {
    @include flexbox($alignItems:center, $justifyContent:center);
    @include padding($padding: 12px 0 5px);
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    font-family: $regular-font;

    color: $dark;

    &:not(:last-child) {
        border-bottom: 1px solid #EEEEEE;
    }

    @include tablet {
        @include padding($padding: 14px 0 5px);
    }

    @include laptop {
        @include padding($padding: 18px 0 5px);
    }

    &.selected {
        background-color: $gray;
    }
}