@import "../../../styles/all.scss";

.container {
    box-sizing: border-box;
    width: 100%;
    position: relative;
    @include padding($padding:25px 38px 30px);
    @include margin($marginBottom:-40px);

    @include backgroundImage($imgpath:'../../../assets/images/header.svg', $repeat: no-repeat, $size:cover, $position:bottom);

    @include tablet {
        @include padding($padding:49px 38px);
        @include margin($marginBottom:-60px);
        @include backgroundImage($imgpath:'../../../assets/images/header-tablet.svg')
    }

    @include laptop {
        @include margin($marginBottom:-30px);
    }
}

.content {
    @include flexbox($justifyContent:space-between, $alignItems:center)
}

.basket {
    position: relative;
    width: 42px;
    height: 31px;

    span {
        position: absolute;
        top: -45%;
        right: -15%;
        color: $white;
    }
}

.clip-vector {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
}

.back {
    @include flexbox($alignItems:center);
    cursor: pointer;
    color: $white;

    p {
        font-family: $regular-font;
    }

    svg {
        @include margin($marginRight:10px);
    }
}