@import "../../../../styles/all.scss";

.container {
    @include flexbox($alignItems:center);
    width: 100%;

    label {
        @include margin($marginLeft:12px);
        cursor: pointer;
        width: 100%;
    }

    position: relative;
    user-select: none;

    input {
        visibility: hidden;
        height: 20px;
        width: 20px;
    }
}

.mark {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    height: 21px;
    width: 21px;
    color: $white;
    border: 1px solid $gray;
    border-radius: 50%;
    user-select: none;
}

input {
    &:checked~.mark {
        background-color: $pink;
        border-color: $pink;
    }

    &:checked~.mark:after {
        display: block;
        color: $white !important;
    }

    &:checked:disabled~.mark {
        background-color: $gray;
        border-color: $gray;
    }
}

.mark:after {
    content: '✔';
    transform: translate(3px);
    display: none;
}