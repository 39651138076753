/* Large title */
.large-text {
    font-family: $primary-font;
    font-size: $fs-xxl;
    line-height: $lh-xxl;
    font-weight: $fw-semibold;
}

/* Title 1 */
.title_text_1 {
    font-family: $primary-font;
    font-size: $fs-xl;
    line-height: $lh-xl;
    font-weight: $fw-semibold;
}

/* Title 2 */
.title_text_2 {
    font-family: $primary-font;
    font-size: $fs-lg;
    line-height: $lh-lg;
    font-weight: $fw-semibold;
}

/* Title 3 */
.title_text_3 {
    font-family: $primary-font;
    font-size: $fs-sm;
    line-height: $lh-sm;
    font-weight: $fw-semibold;
}

/* Heading */
.heading-text {
    font-family: $primary-font;
    font-size: $fs-xs;
    line-height: $lh-xs;
    font-weight: $fw-semibold;
}

/* Body */
.body-text {
    font-size: $fs-xxs;
    line-height: $lh-xxs;
    font-weight: $fw-normal;
}

/* Small */
.small-text {
    font-size: $fs-xxxs;
    line-height: $lh-xxxs;
    font-weight: $fw-semibold;
}