@import "../../../../styles/all.scss";

.basket-popup {
  background: #FFFFFF;
  padding: 23px 25px 19px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
  border-radius: 25px 0px 25px 25px;
  max-width: 181px;
  position: fixed;
  top: 10%;
  right: 4%;
  z-index: 99;
  cursor: default;

  p {
    color: $dark
  }

  @include laptop {
    top: 12%;
    right: 2%;
  }
}

.item {
  @include margin($marginTop:11px);
  @include flexbox($alignItems:center)
}

.item-image {
  @include margin($marginRight:13px);

  img {
    width: 42px;
    height: 42px;
    border-radius: 54px;
  }
}

.item-header {
  color: $dark;
  font-family: $medium-font;
}

.item-price {
  @include margin($marginTop:15px);
  color: $dark;
  font-family: $medium-font;
}