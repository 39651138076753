@import "../../../styles/all.scss";

.container {
    width: 100%;
}

.wait-time {
    @include flexbox($alignItems:center);
    @include padding($padding:50px 30px 15px);
    background-color: $blue;
    font-size: 18px;
    color: $white;
    width: 100%;
    cursor: pointer;

    img {
        @include margin($marginRight:13px);
    }

    span {
        @include margin($marginLeft:6px);
        font-family: $regular-font;
        font-weight: $fw-bold;
        font-size: 15px;
        flex: 1;
        padding-top: 5px;
    }

    @include tablet {
        @include flexbox($justifyContent:center);
        @include padding($paddingTop:90px);
    }

    @include laptop {
        @include padding($paddingTop:60px);
    }
}

.top-section-arrow {
    position: absolute;
    bottom: -115px;
    left: 30px;
    z-index: -1;
}


.section-top {
    @include flexbox($alignItems:flex-end);
    @include padding($padding:0 20px);
    position: relative;

    svg {
        @include margin($marginTop:-80px);
        z-index: -1;
    }

    h4 {
        color: $dark;
        @include margin($marginLeft:15px, $marginBottom:15px)
    }

    @include tablet {
        @include flexbox($justifyContent:center);
    }
}

.categories {
    @include margin($marginTop:30px);
    @include padding($padding:0 18px);

    @include tablet {
        @include padding($padding:0 30px);
    }

    @include laptop {
        @include padding($padding:0 50px);
    }

    @include desktop {
        @include padding($padding:0 70px);
    }
}

.header {
    @include margin($marginBottom:15px)
}

.list {
    @include margin($marginBottom:50px);

    @include tablet {
        @include flexbox($alignItems:center, $wrap: wrap);
    }
}

.list-item {
    &:not(:last-child) {
        @include margin($marginBottom:18px)
    }

    @include backgroundImage($repeat:no-repeat);

    @include tablet {
        &:not(:last-child) {
            @include margin($marginBottom:10px, $marginRight:18px)
        }
    }

    @include laptop {
        &:not(:last-child) {
            @include margin($marginBottom: 0px, $marginRight:18px)
        }
    }
}

.list-link {
    @include flexbox($alignItems:center);
    @include padding($padding:13px 29px);
    width: 100%;
    height: 100%;
    text-decoration: none;
}

.list-item-image {
    @include flexbox($alignItems:center, $justifyContent:center);
    @include margin($marginRight:13.5px);
    width: 30px;
    height: 30px;
}

.list-item-text {
    color: $white;
}

.category-option {
    background-size:100% 58px;
}
