@import "../../../styles/all.scss";

.container {
    width: 100%;
}

.content {
    @include margin($margin:70px 0);
    @include padding($padding:0 18px);

    @include tablet {
        @include padding($padding:0 30px);
    }

    @include laptop {
        @include padding($padding:0 50px);
    }

    @include desktop {
        @include margin($margin:80px 0);
        @include padding($padding:0 70px);
    }
}

.total-container {
    @include margin($marginTop:40px);

    @include tablet {
        @include flexbox($alignItems:center, $justifyContent:flex-end)
    }
}

.subtotal-container {
    width: 100%;
    @include margin($marginBottom:20px);
    @include flexbox($alignItems:center, $justifyContent:space-between);
    color: $dark;

    @include tablet {
        width: 350px;
        @include margin($marginBottom:0, $marginRight:40px);
    }

    div:first-child {
        font-family: $medium-font;
    }
}

.apple-pay-container {
    width: 100%;
    @include flexbox($alignItems:center, $justifyContent:space-between);
    @include padding($padding:21px 26px);
    background: $white;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    border-radius: 17px;
    cursor: pointer;

    @include tablet {
        width: 350px;
    }
}

.apple-pay-logo {
    @include flexbox($alignItems:center, $justifyContent:center);
    @include padding($padding:14px);
    border: 0.5px solid $dark;
    border-radius: 8px;
}

.arrow-right-icon {
    svg {
        height: 18px;
        width: 18px;
    }
}

.proceed-container {
    @include margin($marginTop:30px);
    @include flexbox($direction:column, $alignItems:center);
    width: 100%;

    button {
        &:not(:first-child) {
            @include margin($marginTop: 16px);
        }
    }

    @include tablet {
        @include margin($marginTop:50px);
        @include flexbox($justifyContent:flex-end);

        button {
            &:not(:first-child) {
                @include margin($marginTop: 0, $marginLeft: 26px);
            }
        }
    }
}

.continue-shopping-button {
    color: $lilac;

    svg path {
        fill: $white;
        stroke: $lilac;
    }
}

.proceed-payment-button {
    color: #ffffff;

    svg path {
        fill: $lilac;
        stroke: $lilac;
    }
}

.add-button-container {
    width: 100%;
    @include flexbox($justifyContent:center);
    @include margin($marginTop:80px);
}

.add-button {
    color: $lilac;

    svg path {
        fill: #ffffff;
        stroke: $lilac;
    }
}


   .allergen-btn-container {
        @include margin($marginTop:17px);

        button {
            color: $white;

            svg path {
                fill: $blue;
                stroke: $blue;
            }
        }

    }
