@import "../../../../styles/all.scss";

.container {
    width: 100%;
    @include margin($marginTop:50px);

    div {
        font-family: $regular-font;
        font-size: $fs-xsm;
    }
}

.form {
    width: 100%;
}

.area-number-container {
    @include margin($marginBottom:8px);
}

.area-number-header {
    @include flexbox($alignItems:center);

    svg {
        @include margin($marginRight:8px);

        path {
            fill: $dark-green
        }
    }

    p {
        color: $dark
    }
}

.error {
    color: red !important;
}

.allergy-warning {
    @include flexbox($alignItems:center);
    @include margin($marginTop:25px);

    svg {
        min-width: 16px;
        min-height: 16px;
        @include margin($marginRight:8px);

        path {
            fill: $dark-green
        }
    }

    p {
        color: $dark;
        font-size: 13px;
        line-height: 16px;
    }

    a {
        @include margin($marginLeft:4px);
        color: $lilac;
        text-decoration: underline;
    }
}