@import "../../../../styles/all.scss";

.section-header {
    @include margin($marginBottom:20px);
}


.next-steps-container {
    color: $dark;
    @include margin($marginTop:30px);

    @include tablet {
        @include margin($marginTop:50px);
    }

    p {
        @include margin($marginTop:8px);
    }
}

.button-container {
    width: 100%;
    @include margin($marginTop:22px);
    @include flexbox($justifyContent:flex-end);
}

.show-more {
    color: #ffffff;

    svg path {
        fill: #6FAFB9;
        stroke: #6FAFB9;
    }
}

.choose-button {
    color: #ffffff;

    svg path {
        fill: $lilac;
        stroke: $lilac;
    }
}

.show-more-container {
    @include flexbox($justifyContent:center);
    @include margin($marginTop:8px);
}

.tabs-container {
    width: 100%;
    @include flexbox($justifyContent:center);
}

.tabs {
    @include flexbox();
    @include margin($marginBottom:21px);
    height: 40px;
    width: 95%;
    box-sizing: border-box;

    @include tablet {
        max-width: 350px;
    }
}

.tab {
    @include flexbox($justifyContent:center, $alignItems:center, $grow:1);
    border-color: $blue;
    color: $blue;
    border-top: 2px solid;
    border-bottom: 2px solid;

    box-sizing: border-box;
    cursor: pointer;
    font-family: $medium-font;

    &:first-child {
        border-left: 2px solid;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
    }

    &:last-child {
        border-right: 2px solid;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
    }

    &:hover {
        color: $white;
        border-color: $blue;
        background-color: $blue;
        opacity: 0.7;
    }
}

.selected-tab {
    color: $white;
    border-color: $blue;
    background-color: $blue;

    &:first-child {
        border-left-color: $blue;
    }

    &:last-child {
        border-right-color: $blue;
    }
}

.sorbet-container,
.vegan-container {
    h1 {
        color: $dark;
        @include margin($marginBottom:10px);
    }
}