@import "../../../../../styles/all.scss";

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $white;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    border-radius: 25px;
    @include padding($padding:25px);
    min-width: 70%;

    @include tablet {
        @include padding($padding:30px);
        min-width: 400px;
    }

    @include laptop {
        min-width: 500px;
    }

    @include desktop {
        min-width: 600px;
    }

    h1 {
        text-align: center;
        color: $dark
    }
}

.btn-container {
    @include margin($marginTop:16px);
    @include flexbox($justifyContent:center);
    width: 100%;
}

.close-button {
    color: $lilac;
    max-width: 160px;
    @include margin($marginRight:15px);

    svg path {
        stroke: $lilac;
        fill: $white;
    }

    @include tablet {
        max-width: 180px;
    }

    @include laptop {
        max-width: inherit;
    }

    &:disabled {
        color: $white;
    }
}

.remove-button {
    color: $white;
    max-width: 160px;

    svg path {
        stroke: $lilac;
        fill: $lilac;
    }

    @include tablet {
        max-width: 180px;
    }

    @include laptop {
        max-width: inherit;
    }
}

.close-icon {
    position: absolute;
    top: 9%;
    right: 3%;
    background-color: transparent;
    border: none;
    width: 30px;
    height: 30px;
    cursor: pointer;

    svg {
        width: 14px;
        height: 14px;
    }

    &:disabled {
        svg path {
            fill: $gray
        }
    }
}