@import "../../../../styles/all.scss";

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $white;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
  border-radius: 25px;
  @include padding($padding:45px 30px 30px 30px);
  min-width: 70%;
  font-weight: $fw-normal;
  font-size: 22px;
  font-family: $primary-font;
  color: $dark;

  @include tablet {
    @include padding($padding:40px);
    min-width: 400px;
  }

  @include laptop {
    min-width: 500px;
  }

  @include desktop {
    min-width: 600px;
  }

  .title_text_2 {
    text-align: start;
  }
}

.heading {
  font-weight: $fw-normal;
  font-size: 22px;
  font-family: $primary-font;
  color: $dark;
  text-align: center;
  display: block;
}

.wait-times-list {
  margin-top: 10px;
  font-size: 1rem;
}

.btn-container {
  @include margin($marginTop:10px);
  @include flexbox($justifyContent:center);
  width: 100%;
}

.closeIcon {
    position: absolute;
    top: 18px;
    right: 20px;
    background-color: transparent;
    border: none;
    padding: 10px;
}



.close-button {
  color: #ffffff;
  max-width: 160px;

  svg path {
    fill: $lilac;
    stroke: $lilac;
  }

  @include tablet {
    max-width: 180px;
  }

  @include laptop {
    max-width: inherit;
  }
}