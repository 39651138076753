@import "../../../../styles/all.scss";

.section-header {
    @include margin($marginBottom:20px);
}

.section-item-header {
    @include margin($marginTop:40px);
    @include margin($marginBottom:20px);
}

.button-container {
    width: 100%;
    @include margin($marginTop:22px);
    @include flexbox($justifyContent:flex-end);
}

.choose-button {
    color: #ffffff;

    svg path {
        fill: $lilac;
        stroke: $lilac;
    }
}

.basket-button-container {
    @include margin($marginTop: 30px);
    @include flexbox($direction:column, $alignItems:center);

    button {
        &:not(:first-child) {
            @include margin($marginTop: 16px);
        }
    }

    @include tablet {
        @include flexbox($justifyContent:center);

        button {
            &:not(:first-child) {
                @include margin($marginTop: 0, $marginLeft: 26px);
            }
        }
    }
}

.next-steps-container {
    color: $dark;
    @include margin($marginTop:30px);

    @include tablet {
        @include margin($marginTop:50px);
    }

    p {
        @include margin($marginTop:8px);
    }
}